import { InfoOutlineIcon } from '@chakra-ui/icons';

import { HeadsetIcon, IntegrationIcon, SystemIcon } from './icons';

export const PROFILE_SUB_ROUTES = [
  {
    Icon: IntegrationIcon,
    title: 'Integrations',
    route: 'integrations',
  },
  // To be implemented in future
  // {
  //   Icon: SmartSaveIcon,
  //   title: 'SmartSave Settings',
  //   route: 'smart-save',
  // },
  {
    Icon: SystemIcon,
    title: 'System Details',
    route: 'system-details',
  },
  {
    Icon: HeadsetIcon,
    title: 'Contact Us',
    route: 'contact',
  },
  {
    Icon: InfoOutlineIcon,
    title: 'About',
    route: 'about',
  },
];
