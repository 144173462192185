import React, { useCallback, useState } from 'react';
import { HTTP } from '@awesome-cordova-plugins/http';
import { Wifi } from '@capacitor-community/wifi/src';
import { WarningIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, FormControl, FormLabel, Heading, Switch, Text, useToast, VStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Card from 'clipsal-cortex-ui/src/components/card/Card';

import PageBase from '../../../common/components/PageBase';
import TopNav from '../../../common/components/TopNav';
import {
  WifiDetailsFormData,
  wifiDetailsSchema,
} from '../../../common/components/wifi-details-form/wifi-config-helpers';
import WifiDetailsForm from '../../../common/components/wifi-details-form/WifiDetailsForm';
import { useBulkPathSegmentReplace } from '../../../common/hooks/use-bulk-path-segment-replace';
import { selectSite } from '../../site/siteSlice';
import { WIFI_DIRECT_BASE_URL } from './constants';
import { selectBearerToken, selectSiteDeviceId } from './schneiderChargeSetupSlice';

type WifiDetailsBody = {
  SSID: string;
  WifiPasscode: string;
  SecurityOption: boolean;
};

/* istanbul ignore next -- @preserve */
const putWifiDetails = async (data: WifiDetailsBody, bearerToken: string) =>
  await HTTP.put(`${WIFI_DIRECT_BASE_URL}/evse/v1/wificonnectivitysettings`, data, {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${bearerToken}`,
  });

export default function SchneiderChargeHomeWifiDetails() {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<WifiDetailsFormData>({
    defaultValues: { ssid: '', password: '' },
    resolver: yupResolver(wifiDetailsSchema),
  });
  const { site_id: siteId } = useSelector(selectSite);
  const navigate = useNavigate();
  const toast = useToast();
  const bearerToken = useSelector(selectBearerToken);
  const siteDeviceId = useSelector(selectSiteDeviceId);
  const device = useSelector(selectSite).devices.find((device) => device.id === siteDeviceId);
  const hasActivatedEVCharger = device && device.operating_state !== 'NOT_CONNECTED';
  const [isRequestPending, setIsRequestPending] = useState(false);
  const [isConnectingToHomeNetwork, setIsConnectingToHomeNetwork] = useState(false);
  const [hasFailedReconnectingToHomeNetwork, sethasFailedReconnectingToHomeNetwork] = useState(false);
  const [useEthernet, setUseEthernet] = useState(false);
  const [activate, enterPin] = useBulkPathSegmentReplace(['activate', 'enter_pin']);

  /* istanbul ignore next -- @preserve */
  const handleReconnectToHomeNetwork = useCallback(async () => {
    const data = getValues();
    setIsConnectingToHomeNetwork(true);
    try {
      await Wifi.connect({ ...data, isHiddenSsid: false });
      navigate(hasActivatedEVCharger ? `/site/${siteId}/system_details` : activate);
    } catch (error) {
      sethasFailedReconnectingToHomeNetwork(true);
      toast({ title: 'Failed to connect to home network!', status: 'error' });
    }
    setIsConnectingToHomeNetwork(false);
  }, [getValues, hasActivatedEVCharger, navigate, siteId, toast, activate]);

  const handleWifiFormSubmit = useCallback(
    async (data: WifiDetailsFormData) => {
      setIsRequestPending(true);
      try {
        await putWifiDetails(
          {
            SSID: data.ssid,
            WifiPasscode: data.password,
            SecurityOption: true,
          },
          bearerToken ?? ''
        );
        toast({
          title: 'Wifi details configured successfully!',
          description: 'Please wait while we connect your phone to your home network',
          status: 'success',
        });

        // reconnect to home wifi
        handleReconnectToHomeNetwork();
      } catch (error) {
        toast({ title: 'Failed to configure wifi details!', status: 'error' });
      }
      setIsRequestPending(false);
    },
    [bearerToken, toast, handleReconnectToHomeNetwork]
  );

  return (
    <PageBase>
      <TopNav title="Connect to your Home Network" backURL={enterPin} />
      <Card p={8}>
        {hasFailedReconnectingToHomeNetwork ? (
          <>
            <VStack>
              <WarningIcon color="red" boxSize={20} />
              <Text fontSize="lg" fontWeight="bold">
                Failed to connect to home network!
              </Text>
            </VStack>

            <VStack>
              <Button
                variant="solid"
                onClick={handleReconnectToHomeNetwork}
                rounded={20}
                w="100%"
                maxW={350}
                borderRadius={50}
                colorScheme="dusk100"
                fontWeight={400}
                mt={10}
                isLoading={isConnectingToHomeNetwork}
                loadingText="Connecting..."
              >
                Try Again
              </Button>
              <Button
                mt={2}
                data-testid={'skip-device-setup'}
                colorScheme={'customBlue'}
                variant="link"
                width="100%"
                maxWidth={'300px'}
                onClick={() => navigate(hasActivatedEVCharger ? `/site/${siteId}/system_details` : activate)}
              >
                I've connected to home network manually
              </Button>
            </VStack>
          </>
        ) : (
          <>
            <FormControl>
              <Flex justify={'space-between'}>
                <FormLabel>Connected to Ethernet instead?</FormLabel>
                <Switch id="ethernet-connection" onChange={(e) => setUseEthernet(e.target.checked)} />
              </Flex>
            </FormControl>
            {useEthernet ? (
              <VStack mt={6}>
                <Text textAlign={'left'} w="100%">
                  Before proceeding ahead, please connect your phone to your home network either using Wi-Fi or using an
                  Ethernet cable.
                </Text>
                <Button
                  variant="solid"
                  onClick={() => navigate(hasActivatedEVCharger ? `/site/${siteId}/system_details` : activate)}
                  rounded={20}
                  minW={260}
                  borderRadius={50}
                  colorScheme="dusk100"
                  fontWeight={400}
                  mt={4}
                >
                  Next
                </Button>
              </VStack>
            ) : (
              <Box mt={8}>
                <Heading size="sm">Enter your Home Wi-fi Details</Heading>
                <WifiDetailsForm
                  isLoading={false}
                  onSubmit={handleWifiFormSubmit}
                  containerProps={{ mt: 4 }}
                  buttonName="Next"
                  buttonProps={{
                    isDisabled: isRequestPending,
                    mt: 0,
                    isLoading: isRequestPending || isConnectingToHomeNetwork,
                    loadingText: 'Saving...',
                  }}
                  {...{ register, handleSubmit, errors }}
                />
              </Box>
            )}
          </>
        )}
      </Card>
    </PageBase>
  );
}
